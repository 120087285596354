import React, { useState } from 'react'
import styled from 'styled-components'
import { transition } from '../../assets/style-helpers'
import { borderRadius, colors } from '../../assets/style-variables'

const TextInput = styled.input`
  border-radius: ${borderRadius};
  border: 1px solid ${colors.gray};
  outline: 0;
  ${transition('border-color')};
  font-size: 14px;
  padding: 6px 10px;
  width: 100%;

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    border: 1px solid ${colors.baseColor};
  }
`

export const Input = ({
  type = 'text',
  onChange,
  innerRef,
  defaultValue,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue || '')

  const handleChange = event => {
    const { name, value } = event.target
    setValue(value)
    onChange && onChange({ name, value })
  }

  return (
    <TextInput
      type={type}
      onChange={handleChange}
      value={value}
      ref={innerRef}
      {...props}
    />
  )
}
