import { createReducer } from 'reduxsauce'
import { Types } from './actions'

export const INITIAL_STATE = {
  // players: ['Антон', 'Маша', 'Иван', 'Инна'],
  players: [],
  cardConfig: [
    {
      section: 'who',
      title: 'Кто?',
      items: [
        { name: 'green', title: 'Грин' },
        { name: 'mastard', title: 'Мастард' },
        { name: 'peacock', title: 'Пикок' },
        { name: 'plam', title: 'Плам' },
        { name: 'scarlet', title: 'Скарлет' },
      ],
    },
    {
      section: 'what',
      title: 'Чем?',
      items: [
        { name: 'candlestick', title: 'Подсвечник' },
        { name: 'dagger', title: 'Кинжал' },
        { name: 'gun', title: 'Револьвер' },
        { name: 'rope', title: 'Веревка' },
        { name: 'wrench', title: 'Гаечный ключ' },
      ],
    },
    {
      section: 'where',
      title: 'Где?',
      items: [
        { name: 'ballroom', title: 'Бальный зал' },
        { name: 'billiard_room', title: 'Бильярдная' },
        { name: 'winter_garden', title: 'Зимний сад' },
        { name: 'dining_room', title: 'Столовая' },
        { name: 'bathroom', title: 'Ванная' },
        { name: 'kitchen', title: 'Кухня' },
        { name: 'library', title: 'Библиотека' },
        { name: 'living_room', title: 'Гостиная' },
        { name: 'cabinet', title: 'Кабинет' }
      ],
    },
  ]
}

const addPlayer = (
  state = INITIAL_STATE,
  action,
) => {
  const { players } = state
  const { player } = action
  return {
    ...state,
    players: [
      ...players,
      player,
    ],
  }
}

const deletePlayer = (
  state = INITIAL_STATE,
  action,
) => {
  const { index } = action
  const { players } = state
  return {
    ...state,
    players: [
      ...players.slice(0, index),
      ...players.slice(index + 1),
    ],
  }
}

const editPlayer = (
  state = INITIAL_STATE,
  action,
) => {
  const { index, player } = action
  const { players } = state
  return {
    ...state,
    players: [
      ...players.slice(0, index),
      player,
      ...players.slice(index + 1),
    ],
  }
}

const changePlayersOrder = (
  state = INITIAL_STATE,
  action,
) => {
  const { players } = action
  return {
    ...state,
    players
  }
}

export const HANDLERS = {
  [Types.ADD_PLAYER]: addPlayer,
  [Types.DELETE_PLAYER]: deletePlayer,
  [Types.EDIT_PLAYER]: editPlayer,
  [Types.CHANGE_PLAYERS_ORDER]: changePlayersOrder,
}

console.log({ HANDLERS })
export const reducer = createReducer(INITIAL_STATE, HANDLERS)
