import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from '../../assets/style-variables'
import { Button, CheckBox, Modal, Radio } from '../../components'

const RadioWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const Subheader = styled.h3`
  margin-bottom: 5px;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`

const TableCell = styled.td`
  padding: ${props => props.noPaddings ? '0' : '5px'};
  border: 1px solid ${colors.gray};
`

const TableCellSticky = styled(TableCell)`
  position: sticky;
  top: 5px;
  background-color: ${colors.white};
`

const TableRow = styled.tr`
  ${props => props.colored && `background-color: ${colors.baseColor};color: ${colors.white};`}
`

const radioDataSource = [
  { value: 'click', label: 'По клику' },
  { value: 'dropdown', label: 'Выпадающий список' },
]

const iconSet = [
  { type: 'faTimes', color: colors.red },
  { type: 'faCheck', color: colors.green },
  { type: 'faQuestion', color: colors.baseColor },
  { type: 'faLongArrowAltLeft', color: colors.baseColor },
  { type: 'faLongArrowAltRight', color: colors.baseColor },
]

export const GameCard = () => {
  const [checkStyle, setCheckStyle] = useState('click')
  const [resetGame, setResetGame] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const { cardConfig, players } = useSelector(store => store)

  const handleResetGame = () => {
    setResetGame(true)
    setModalVisible(false)

    setTimeout(() => {
      setResetGame(false)
    }, 100)
  }

  if (!players.length) {
    return <Redirect to='/' />
  }

  if (players.length < 2) {
    return <Redirect to='/players-setup' />
  }

  return (
    <>
      <Table>
        <thead>
          <TableRow>
            <TableCell />
            {players.map(player => (
              <TableCellSticky key={player}>
                <strong style={{ textOverflow: 'ellipsis' }}>{player}</strong>
              </TableCellSticky>
            ))}
          </TableRow>
        </thead>
        <tbody>
        {cardConfig.map(({ title, items }) => (
          <React.Fragment key={title}>
            <TableRow colored>
              <TableCell colSpan={players.length + 1}>{title}</TableCell>
            </TableRow>
            {items.map(({ name, title }) => (
              <tr key={name}>
                <TableCell>{title}</TableCell>
                {players.map((player, index) => (
                  <TableCell
                    key={player}
                    noPaddings
                  >
                    <CheckBox
                      reset={resetGame}
                      checkStyle={checkStyle}
                      iconSet={index === 0 ? iconSet.slice(0, 3) : iconSet.slice(3)}
                    />
                  </TableCell>
                ))}
              </tr>
            ))}
          </React.Fragment>
        ))}
        </tbody>
      </Table>

      {modalVisible
        ? (
          <Modal
            onCancel={() => setModalVisible(false)}
            onSubmit={handleResetGame}
            contentText='Вы уверены? Текущие заметки будут стерты!'
          />
        )
        : (
          <Button
            buttonText='Начать игру заново'
            wide
            onClick={() => setModalVisible(true)}
          />
        )}
    </>
  )
}
