import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { color } from '../../assets/mixins'
import { transition } from '../../assets/style-helpers'
import { colors } from '../../assets/style-variables'

const Container = styled(FontAwesomeIcon)`
  margin-right: ${props => props.right}px;
  font-size: ${props => props.fontSize}px;
  ${transition('color')};
  ${props => {
    if (props.disabled) {
      return `color: ${colors.gray};`
    }
    return color('color', props.color)
  }}
  cursor: pointer;
  ${props => props.nopointerevents && 'pointer-events: none;'}
`

export const Icon = ({
  type,
  onClick,
  disabled,
  color,
  nopointerevents,
  fontSize,
  right
}) => {
  const handleClick = event => {
    event.stopPropagation()
    event.preventDefault()
    onClick && onClick()
  }

  const pointerEvents = nopointerevents || disabled ? 1 : 0

  return (
    <Container
      icon={icons[type]}
      color={color}
      onClick={handleClick}
      disabled={disabled}
      nopointerevents={pointerEvents}
      fontSize={fontSize}
      right={right}
    />
  )
}
