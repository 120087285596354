import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/style-variables'
import { Icon } from '../../../components'
import { faTelegramPlane, faVk } from '@fortawesome/free-brands-svg-icons'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 74px;
  background-color: ${colors.baseColor};
  color: ${colors.white};
`

const Wrapper = styled.div`
  max-width: 50px;
  display: flex;
  justify-content: space-between;
`

const Span = styled.span`
  margin-bottom: 5px;
`

const contacts = [
  {icon: faTelegramPlane, url: 'https://t.me/romant094'},
  {icon: faVk, url: 'https://vk.com/romant094'},
]

export const Footer = () => {
  const openWindow = url => {
    const link = window.open(url, '_blank')
    link.focus()
  }

  return (
    <Container>
      <Span>
        Связь с разработчиком
      </Span>
      <Wrapper>
        {contacts.map(({ icon, url }) => (
          <Icon
            key={url}
            icon={icon}
            fontSize={20}
            color={colors.white}
            onClick={() => openWindow(url)}
          />
        ))}
      </Wrapper>
    </Container>
  )
}
