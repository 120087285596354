import React, { useEffect } from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/style-variables'
import { Button } from '../Button'
import { Portal } from '../Portal'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  & button:first-child {
    margin-right: 10px;
  }
`

const ModalBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
`

const ModalLayout = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 20px);
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  z-index: 101;
  border-radius: 4px;
`

const ModalContent = styled.div`
  padding: 10px;
`

export const Modal = ({
  onSubmit,
  onCancel,
  contentText,
}) => {
  const handleSubmit = () => {
    onSubmit && onSubmit()
  }

  const handleCancel = () => {
    onCancel && onCancel()
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <Portal>
      <Container>
        <ModalBackground />
        <ModalLayout>
          <ModalContent>{contentText}</ModalContent>
          <ModalFooter>
            <Button
              buttonText='Нет'
              onClick={handleCancel}
            />
            <Button
              buttonText='Да'
              onClick={handleSubmit}
            />
          </ModalFooter>
        </ModalLayout>
      </Container>
    </Portal>
  )
}
