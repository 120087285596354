import React from 'react'
// import { Link } from 'react-router-dom'
import styled from 'styled-components'
// import { pages } from '../../../assets/pages'
// import { colors } from '../../../assets/style-variables'
// import { Icon } from '../../../components'
import logo from './images/cluedo-logo.png'

const Container = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  //justify-content: space-between;
  padding: 10px;
  width: 100%;
`
const Logo = styled.img`
  display: block;
  height: auto;
  max-width: 150px;
`

// const Wrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `
//
// const StyledLink = styled(Link)`
//   margin-right: 10px;
//
//   &:last-child {
//     margin-right: 0;
//   }
// `

export const Header = () => {
  return (
    <Container>
      <Logo src={logo} alt='Cluedo' />
      {/*<Wrapper>*/}
      {/*{pages.map(page => (*/}
      {/*  <StyledLink*/}
      {/*    key={page.title}*/}
      {/*    to={page.url}*/}
      {/*  >*/}
      {/*    {page.title}*/}
      {/*  </StyledLink>*/}
      {/*))}*/}
      {/*</Wrapper>*/}
      {/*<Icon*/}
      {/*  type='faBars'*/}
      {/*  fontSize={30}*/}
      {/*  color={colors.black}*/}
      {/*/>*/}
    </Container>
  )
}
