import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Input } from '../../components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

const TextInput = styled(Input)`
  margin-bottom: 20px;
`
const Subheader = styled.h3`
  margin-bottom: 5px;
`

export const StartPage = ({
  actions: {
    addPlayer,
  }
}) => {
  const dispatch = useDispatch()
  const [redirect, setRedirect] = useState(false)
  const [playerName, setPlayerName] = useState('')

  if (redirect) {
    return <Redirect to='/players-setup' />
  }

  const handleClick = () => {
    dispatch(addPlayer(playerName))
    setRedirect(true)
  }

  return (
    <Container>
      <Subheader>Введите свое имя</Subheader>
      <TextInput
        onChange={({ value }) => setPlayerName(value)}
        placeholder='Минимум 2 символа'
      />
      <Button
        buttonText='Начать игру'
        size={30}
        onClick={handleClick}
        disabled={playerName.length < 2}
        wide
      />
    </Container>
  )
}
