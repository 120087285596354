import React from 'react'
import styled from 'styled-components'
import { color } from '../../assets/mixins'
import { transition } from '../../assets/style-helpers'
import { borderRadius, colors } from '../../assets/style-variables'

const paddings = {
  icon: '10px',
}

const Container = styled.button`
  ${transition('background-color')};
  ${color('background-color')}
  color: ${colors.white};
  border-radius: ${borderRadius};
  border: none;
  cursor: pointer;
  
  padding: ${props => paddings[props.type] || '5px 20px'};
  font-size: ${props => props.size}px;
  width: ${props => props.wide ? '100%' : 'auto'};
  
  &:disabled {
    ${color('background-color', colors.gray)}
  }
`

export const Button = ({
  buttonText,
  wide,
  ...props
}) => {
  return (
    <Container wide={wide} {...props}>{buttonText}</Container>
  )
}

