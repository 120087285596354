import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Button, Icon, Input } from '../../components'
import { colors } from '../../assets/style-variables'
import { reorder } from './helpers'
import { ERRORS, HINT_ADD_PLAYERS } from './constants'

const AddForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const AddFormRow = styled.form`
  display: flex;
  align-items: center;
`

const ErrorText = styled.div`
  color: ${colors.red};
`

const TextInput = styled(Input)`
  margin-right: 10px;
  flex: 1;
`

const Player = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid ${colors.gray};
  background-color: ${colors.white};
`

const PlayerInfo = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const EditablePlayer = styled.div`
  display: flex;
  align-items: center;
  width: 70%;

  * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const PlayerName = styled.div`
  flex: 1;
`

const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const HintAddPlayer = styled.div`
  margin-bottom: 20px;
  line-height: 1.5;
`

const ManagementIcons = styled.div`
  flex: 0;
  display: flex;
`

const Header = styled.h1`
  font-size: 32px;
  margin-bottom: 20px;
`

const DndWrapper = styled.div`
  margin-bottom: 20px;
`

const NextButton = styled(Button)`
  margin: 0 auto;
  display: block;
`

export const PlayersSetup = ({
  actions: {
    changePlayersOrder,
    addPlayer,
    deletePlayer,
    editPlayer,
  },
}) => {
  const inputRef = useRef()
  const editablePlayerInputRef = useRef()
  const dispatch = useDispatch()
  const { players } = useSelector(store => store)
  const [error, setError] = useState('')
  const [editablePlayerIndex, setEditablePlayerIndex] = useState(null)
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    inputRef.current.value = ''
    inputRef.current.focus()
  }, [players])

  const onDragEnd = result => {
    const { destination, source } = result
    console.log({ result })
    if (!destination || !source.index || !destination.index) {
      return
    }
    const data = reorder(
      players,
      source.index,
      destination.index,
    )
    dispatch(changePlayersOrder(data))
  }

  const handleAddPlayer = event => {
    event && event.preventDefault()
    const value = validateValue(inputRef)
    if (!value) {
      return
    }
    dispatch(addPlayer(value))
  }

  const validateValue = ref => {
    const value = ref?.current?.value
    if (value.length < 2) {
      setError(ERRORS.NAME_TOO_SHORT)
      return false
    }
    const formatted = value.trim()
    const notUniqueIndex = players.findIndex(item => formatted.toLowerCase() === item.toLowerCase())
    if (notUniqueIndex !== -1 && notUniqueIndex !== editablePlayerIndex) {
      setError(ERRORS.EXISTING_NAME)
      return false
    }
    return formatted
  }

  const handleEditPlayer = index => {
    setEditablePlayerIndex(index)
  }

  const handleDeletePlayer = index => {
    dispatch(deletePlayer(index))
    if (index === editablePlayerIndex) {
      setEditablePlayerIndex(null)
    }
  }

  const handleSaveEditablePlayer = () => {
    const value = validateValue(editablePlayerInputRef)
    if (!value) {
      return
    }
    dispatch(editPlayer(value, editablePlayerIndex))
    setEditablePlayerIndex(null)
  }

  const handleDiscardEditablePlayer = () => {
    setEditablePlayerIndex(null)
    setError('')
  }

  if (redirect) {
    return <Redirect to='/game-card' />
  }

  return (
    <>
      <Header>Подготовка</Header>
      <HintAddPlayer>{HINT_ADD_PLAYERS}</HintAddPlayer>
      {players.length > 0 && (
        <DndWrapper>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {players.map((player, index) => (
                    <Draggable
                      key={player}
                      draggableId={player}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}
                        >
                          <Player>
                            <PlayerWrapper>
                              <Icon
                                type='faBars'
                                color={colors.gray}
                                fontSize={20}
                                right={10}
                              />
                              <PlayerInfo>
                                <span>{index + 1}.&nbsp;</span>
                                {editablePlayerIndex === index
                                  ? (
                                    <EditablePlayer>
                                      <Input
                                        defaultValue={player}
                                        innerRef={editablePlayerInputRef}
                                      />
                                      <Icon
                                        type='faCheck'
                                        onClick={handleSaveEditablePlayer}
                                        color={colors.green}
                                        fontSize={20}
                                      />
                                      <Icon
                                        type='faBan'
                                        onClick={handleDiscardEditablePlayer}
                                        color={colors.red}
                                        fontSize={20}
                                      />
                                    </EditablePlayer>
                                  )
                                  : <PlayerName>{player} {index === 0 && '(Это вы!)'}</PlayerName>
                                }
                              </PlayerInfo>
                            </PlayerWrapper>
                            <ManagementIcons>
                              <Icon
                                type='faPen'
                                onClick={() => handleEditPlayer(index)}
                                fontSize={20}
                                right={10}
                              />
                              <Icon
                                type='faTrashAlt'
                                color={colors.red}
                                onClick={() => handleDeletePlayer(index)}
                                fontSize={20}
                                disabled={index === 0}
                              />
                            </ManagementIcons>
                          </Player>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </DndWrapper>
      )}
      <AddForm>
        <AddFormRow onSubmit={handleAddPlayer}>
          <TextInput
            innerRef={inputRef}
            onChange={() => setError('')}
            disabled={players.length > 4}
            placeholder='Имя игрока (минимум 2 символа)'
          />
          <Icon
            type='faPlus'
            onClick={handleAddPlayer}
            fontSize={24}
            disabled={players.length > 4}
          />
        </AddFormRow>
        {error && <ErrorText>{error}</ErrorText>}
      </AddForm>
      {players.length > 0 && (
        <NextButton
          buttonText='Продолжить'
          size={30}
          onClick={() => setRedirect(true)}
          disabled={players.length < 2}
        />
      )}
    </>
  )
}
