import React from 'react'
import styled from 'styled-components'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as components from '../../views'
import { Actions } from '../../redux/actions'
import { pages } from '../../assets/pages'

const { Header, Footer } = components

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  flex: 1;
`

const Main = styled.main`
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

export const App = () => {
  return (
    <Container>
      <Header />
      <Main>
        <Switch>
          {pages.map(page => {
            const { url, component } = page
            const Component = components[component]
            return (
              <Route
                key={component}
                exact={url === '/'}
                path={url}
                render={() => <Component actions={Actions} />}
              />
            )
          })}
          <Route path='*'>
            <Redirect to='/' />
          </Route>
        </Switch>
      </Main>
      {/*<Footer />*/}
    </Container>
  )
}
