import { transitionTime } from './style-variables'

export const transition = (
  props,
  time = transitionTime,
) => {
  const array = Array.isArray(props) ? props : [props]
  const transformed = array.map((item, index) => (
    `${item} ${time}${index < array.length - 1 ? ',' : ''}`
  ))
  return ['transition:', ...transformed].join(' ')
}

export const shadeColor = (color, percent) => {
  const getChannelFromColor = (from, to) => (
    parseInt(color.substring(from, to), 16)
  )
  const transformChannel = channel => {
    const value = parseInt(channel * (1 + percent / 100))
    return (value < 255) ? value : 255
  }
  const getFinalValue = channel => (
    channel.toString(16).length === 1
      ? `0${channel.toString(16)}`
      : channel.toString(16)
  )
  const channels = [
    getChannelFromColor(1, 3),
    getChannelFromColor(3, 5),
    getChannelFromColor(5, 7),
  ]
  return `#${channels.map(transformChannel).map(getFinalValue).join('')}`
}
