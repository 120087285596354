import { colors } from './style-variables'
import { shadeColor } from './style-helpers'

export const color = (
  key = 'color',
  color = colors.baseColor,
) => (
  `${key}: ${color};
  &:hover {
    ${key}: ${shadeColor(color, -20)};
  }`
)
