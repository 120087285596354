import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

const createRootElement = id => {
  const div = document.createElement('div')
  div.setAttribute('id', id)
  return div
}

const addRootElement = rootElem => {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling,
  )
}

const usePortal = ({
  positionStyles,
  position,
}) => {
  const rootElemRef = useRef(null)
  const id = 'portal-root'

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`)
    const parentElem = existingParent || createRootElement(id)

    if (!existingParent) {
      addRootElement(parentElem)
    }

    parentElem.appendChild(rootElemRef.current)

    return () => {
      rootElemRef.current.remove()
      if (!parentElem.childElementCount) {
        parentElem.remove()
      }
    }
  }, [id])

  const getRootElem = () => {
    if (!rootElemRef.current) {
      const div = document.createElement('div')
      if (positionStyles) {
        Object
          .entries(positionStyles)
          .forEach(([key, value]) => {
            div.style[key] = `${value}px`
          })
      }
      rootElemRef.current = div
    }
    return rootElemRef.current
  }

  return getRootElem()
}

export const Portal = ({
  children,
  positionStyles,
  position = 'absolute',
  className,
}) => {
  const target = usePortal({
    positionStyles,
    position,
    className,
  })

  return createPortal(children, target)
}
