import { createActions } from 'reduxsauce'

export const {
  Types,
  Creators: Actions,
} = createActions({
  addPlayer: ['player'],
  deletePlayer: ['index'],
  changePlayersOrder: ['players'],
  editPlayer: ['player', 'index'],
})
