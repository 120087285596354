import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body,
  body * {
    margin: 0;
    padding: 0;
    outline: 0;
    outline: none;
    box-sizing: border-box;
  }

  div, main, section, article {
    display: block;
    width: 100%;
  }
`
