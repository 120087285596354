import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useEventListener } from '../../assets/hooks'
import { colors } from '../../assets/style-variables'
import { Icon } from '../Icon'

const Container = styled.div`
  min-height: 30px;
  min-width: 30px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Dropdown = styled.ul`
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  width: 100%;
  box-sizing: content-box;
  border: 1px solid ${colors.gray};
  border-top: none;
  list-style-type: none;
  padding: 0;
  z-index: 1000;
  background-color: ${colors.white};
`

const DropdownItem = styled.li`
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding: 5px 0;
  width: 100%;
  border-bottom: 0.5px solid ${colors.gray};

  &:last-child {
    border-bottom: none;
  }
`

const CheckArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`

const defaultIcons = [
  { type: 'faTimes', color: colors.red },
  { type: 'faCheck', color: colors.green },

  { type: 'faLongArrowAltLeft', color: colors.baseColor },
  { type: 'faLongArrowAltRight', color: colors.baseColor },
]

export const CheckBox = ({
  onClick,
  iconSet = defaultIcons,
  checkStyle = 'click',
  fontSize = 30,
  defaultIndex = 0,
  reset
}) => {
  const dropdownRef = useRef()
  const [index, setIndex] = useState(defaultIndex)
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const { type, color } = iconSet[index - 1] || {}

  useEffect(() => {
    if (checkStyle !== 'dropdown') {
      setDropdownOpened(false)
    }
  }, [checkStyle])


  useEffect(() => {
    if (reset) {
      setIndex(0)
    }
  }, [reset])

  const handleClick = event => {
    event.stopPropagation()
    if (checkStyle === 'click') {
      const value = index === iconSet.length ? 0 : index + 1
      onClick && onClick(value)
      setIndex(value)
    } else {
      setDropdownOpened(true)
    }
  }

  const handleSelectIcon = index => {
    setIndex(index + 1)
    setDropdownOpened(false)
  }

  const handleClickOutside = event => {
    const { target } = event
    if (dropdownRef.current && !dropdownRef.current.contains(target)) {
      setDropdownOpened(false)
    }
  }

  useEventListener('mousedown', handleClickOutside)

  return (
    <Container onClick={handleClick}>
      <CheckArea>
        {(index && (
          <Icon
            type={type}
            color={color}
            fontSize={fontSize}
          />
        )) || null}
      </CheckArea>
      {checkStyle === 'dropdown' && dropdownOpened && (
        <Dropdown>
          {iconSet.map((item, index) => (
            <DropdownItem
              key={item.type}
              onClick={() => handleSelectIcon(index)}
            >
              <Icon
                type={item.type}
                color={colors.baseColor}
                nopointerevents={1}
              />
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Container>
  )
}
