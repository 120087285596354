export const colors = {
  baseColor: '#0d56a4',
  white: '#ffffff',
  black: '#000000',
  gray: '#cccccc',
  red: '#a83b42',
  green: '#1a7730'
}

export const borderRadius = '6px'
export const transitionTime = '0.3s'
