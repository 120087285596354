import React from 'react'
import styled from 'styled-components'
import { transition } from '../../assets/style-helpers'
import { colors } from '../../assets/style-variables'

const Container = styled.div`
  display: flex;
`

const Label = styled.label`
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
`

const RadioCustom = styled.span`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid ${colors.baseColor};
  position: relative;
  display: flex;
  margin-right: 5px;

  ${props => props.checked
    ? `&:after {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: ${+props.checked};
      ${transition('opacity')};
      background-color: ${colors.baseColor};
    }`
    : ''
  }
`

const RadioStandard = styled.input`
  display: none;
`

export const Radio = ({
  labelText,
  checked,
  onChange,
  value,
}) => {
  const handleChange = () => {
    onChange && onChange(value)
  }

  return (
    <Container>
      <Label>
        <RadioCustom checked={checked} />
        <span>{labelText}</span>
        <RadioStandard
          type='radio'
          checked={checked}
          value={value}
          onChange={handleChange}
        />
      </Label>
    </Container>
  )
}
